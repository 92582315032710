@font-face {
  font-family: 'poppinssemibold';
  src: url('../../fonts/poppins-semibold-webfont.woff2') format('woff2'),
       url('../../fonts/poppins-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('../../fonts/roboto-light-webfont.woff2') format('woff2'),
       url('../../fonts/roboto-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('../../fonts/roboto-regular-webfont.woff2') format('woff2'),
       url('../../fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}