$breakpoints: (
  lg: 1420px,
  md: 992px,
  sm: 480px
);

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content; 
     }
  }
}
