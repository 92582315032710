.c-country-wrapper {
  padding-top: 2rem;
  .h2-like {
    @include media-breakpoint-down(md) {
      width: fit-content;
    }
  }
}

.c-country {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--c-grey-100);
  transition: var(--ui-transition);
  &:hover,
  &:active {
  border-bottom: 1px solid var(--c-yellow);
  transition: var(--ui-transition);
  }
  .c-cname {
    font-size: 1.125rem;
    font-weight: 300;
    margin: .25rem 0;
  }
  .c-lang {
    display: flex;
    column-gap: 1.5rem;
    .c-link {
      padding: 1rem 0;
    }
  }
  .left {
    display: flex;
    align-items: center;
    max-width: 50%;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    max-width: 50%;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
}
