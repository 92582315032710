.c-link {
  font-family: var(--font-secondary);
  color: var(--c-blue-600);
  text-decoration: none;
  font-size: .75rem;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}
.c-link-secondary {
  font-size: .85rem;
  color: var(--c-grey-600);
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}