.o-container {
  margin: 0 auto;
  max-width: 1170px;
  @include media-breakpoint-down(md) {
    margin: 0 2rem;
  }
}
.o-wrapper {
  background-color: var(--white);
  padding: 0 6.25rem 6.25rem 6.25rem;
  z-index: 10;
  @include media-breakpoint-down(md) {
    padding: 0 2rem 5rem 2rem;
  }
}
.o-grid {
  &--twocol {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }
  }
}
.o-content {
  padding-top: 2rem;
}
