.h1-like {
  color: var(--white);
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 2rem;
}

.h2-like {
  position: relative;
  color: var(--c-blue-800);
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2rem;
  &--tile {
    width: fit-content;
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: -3rem;
      bottom: -.5rem;
      width: 3rem;
      height: 1rem;
      background-image: url('../../svg/wave.svg');
      background-repeat: no-repeat;
      @include media-breakpoint-down(md) {
        bottom: -1rem;
        right: 0;
      }
    }
  }
}
