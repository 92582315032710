@import '../../styles/settings/settings.index';

.l-footer {
  background: var(--c-blue-200);
  .l-footer-top {
    padding: 2rem 0;
    border-bottom: 1px solid var(--c-blue-300);
    >.o-container {
      display: flex;
      justify-content: center;
      gap: 3rem;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
      }
    }
    .logo-footer {
      width: 118px;
      height: auto;
      img {
        width: 100%;
      }
    }
    .social {
      display: flex;
      gap: .75rem;
    }
  }
  .l-footer-bottom {
    padding: 2rem 0;
    >.o-container {
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      }
    }
  }
  .l-footer-links {
    display: flex;
    gap: 1rem;
    margin: 0;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 0;
    }
  }
  .l-footer-copyright {
    p {
      font-size: .85rem;
      color: var(--c-grey-600);
      margin: 0;
    }
  }
}
