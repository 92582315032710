.custom-cke-subtitle-3 {
  color: var(--c-blue-500);
  font-size: 1.25rem;
  margin-bottom: .75rem;
}
.custom-cke-subtitle-4 {
  font-size: 1.25rem;
  color: var(--c-blue-600);
  margin-bottom: .75rem;
}
.table {
  border-spacing: 0;
  border-collapse: separate;
  margin-bottom: 5rem;
  td {
    border: 1px solid rgba(0,79,159,0.5);
    width: 50%;
    padding: 8px 30px 7px 10px;
  }
  tr {
    &:nth-child(2n+1) {
      background: #F0EFEF;
    }
    &:nth-child(2n) {
      background: #FFFFFF;
    }
  }
  .th-center {
    border: 1px solid rgba(0,79,159,0.5);
    background: #B9CBE5;
    color: var(--c-blue-800);
    text-align: center;
    padding: 8px 30px 7px 10px;
  }
}
.table-horizontal {
  border-spacing: 0;
  border-collapse: separate;
  margin-bottom: 5rem;
  th {
    border: 1px solid rgba(0,79,159,0.5);
    padding: 8px 30px 7px 10px;
  }
  tr {
    &:nth-child(2n+1) {
      background: #F0EFEF;
      th {
        background-color: #B9CBE5;
      }
    }
    &:nth-child(2n) {
      background: #FFFFFF;
      th {
        background-color: #CFDAEE;
      }
    }
  }
  td {
    border: 1px solid rgba(0,79,159,0.5);
    padding: 8px 30px 7px 10px;
  }
}