.o-header {
  padding-top: 3rem;
  .logo-wrapper {
    max-width: 14.75rem;
    img {
      width: 100%;
    }
  }
  .h2-like {
    margin: 4rem 0;
    color: var(--white);
  }
}