:root {
  --body-font: 'roboto', sans-serif;
  --font-secondary: 'poppinssemibold', sans-serif;
  --body-bg: linear-gradient(180deg, #CFDAEE 0%, rgba(255,255,255,1) 80%);
  --white: #FFFFFF;
  --body: #4A4A47;
  --c-grey-100: #F0EFEF;
  --c-grey-600: #777772;
  --c-blue-200: #E4ECF4;
  --c-blue-300: #CFDAEE;
  --c-blue-500: #0070C0;
  --c-blue-600: #004F9F;
  --c-blue-800: #232C77;
  --c-yellow: #FFCC00;
  --ui-transition: .25s ease-in-out;
}